.remove-tube-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: #fffb;
    text-align: center;

    .modal-overlay {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 400px;
    }

    .modal-input {
        margin-bottom: 1em;
    }

    .button-row {
        display: flex;
        flex-direction: row;
    }

    .button-row > button {
        flex-grow: 1;
        flex-basis: 50%;
    }

    .button-row > button+button {
        margin-left: 1em;
    }
}
