html, body {
    height: 100%;
}

.frontpage {
    // flex container so that the footer reaches the bottom
    display: flex;
    flex-direction: column;
    height: 100%;
    // content is centered
    margin: auto;
    text-align: center;
}

.content {
    flex: 1;
}

ul {
    padding: 0;
}

.menu {
    display: flex;
    flex-flow: column;
}

.menu li {
    list-style-type: none;
    font-size: xx-large;
    font-weight: bold;
    padding-top: 1em;
    padding-bottom: 1em;
    flex: 1 1 auto;
}

.menu a {
    text-decoration: none;
    color: black;
  }

.menu a:hover {
    text-decoration: underline;
}

.menu p {
    text-decoration: none;
    color: black;
}

.menu p:hover {
    text-decoration: underline;
}

.submenu {
    display: flex;
    flex-flow: column;
    padding-top: 0.5em;
}

.submenu li {
    list-style-type: none;
    font-size: x-large;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    flex: 1 1 auto;
}
