th {
    text-align: center;
    background-color: #f2f2f2;
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
}

td {
    text-align: center;
}

.header-label {
    display: inline-block;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.sort-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}

.sort-icon img {
    height: 0.8em;
    width: auto;
}

.expandable-row {
    //column-span: 100%;
}
