.preferencebar {
    width: 100%;
    margin-bottom: 0.3em;

    .header {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .toggle {
        display: inline-block;
        flex-grow: 1;
        margin-right: 2px;
    }
}
