.status-overlay {

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 100px;
    z-index: 1000;

    .message {
        display: block;
        text-align: center;
        margin-top: 4px;
        font-size: 16px;
        border: 1px solid green;
        color: green;
        background: #FFF;
    }
}