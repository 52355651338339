.searchbar {
    margin-bottom: 0.3em;
    width: 100%;

    .header {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .toggle {
        display: inline-block;
        flex-grow: 1;
        margin-right: 2px;
    }

    .clear {
        display: inline-block;
        flex-basis: 3em;
        flex-grow: 0;
        font-weight: bold;
        color: #ff0000;
        margin-left: 2px;
    }
}