.show {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-block-end: 2em;
}

.content {
    flex: 1;
}

.tube-editor-container {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}

.button-container {
    margin-top: 1rem;
}

.action-container {
    display: block;
    margin: 1rem 0 1rem 0;
    width: 100%;
}

.action-button {
    display: block;
    width: 100%;
}

.detail-form {
    display: block;
    margin-bottom: 1em;
}