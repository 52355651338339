@import 'common.scss';

header {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    min-height: 4em;
    display: block;
    cursor: pointer;
    user-select: none;  
    text-align: center;
    color: $color-text-primary;

    .logo {
        background: url(./logo.png) no-repeat;
        display: inline-block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 4em;
        width: 4em;
    }
    
    a:link, a:visited, a:hover, a:active {
        color: $color-text-primary;
    }

    h1 {
        font-size: 20px;
        font-weight: bold;
        display: inline-block;
        margin-left: 1em;
        height: 4em;
        vertical-align: top;  
    }    
}
