.comment-panel {

    .headline {
        text-align: center;
    }

    h2 {
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: 1rem;
        color: --primary-color;
    }
    
    .comments {
        margin-bottom: 2rem;
    }

    .comment + .comment {
        margin-top: 2rem;
        color: --primary-color;
    }

    h3 {
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: 0.5rem;
        color: --primary-color;
    }

    .comment-form textarea {
        width: 100%;
        height: 3rem;
        margin-bottom: 0;
    }
}