// fix required because the FloatingLabel label is also at z-index 2 
// and would interfere with the datepicker model
.react-datepicker-popper {
    z-index: 1000;
}

.react-datepicker-wrapper {
    width: 100%;
}

.tube-editor-form {
    
    .form-group {
        margin-bottom: 1rem;
    }
}
