.infobar {
    display: block;
    align-items: center;
    text-align: center;
    border-radius: 4px 4px 4px 4px;
    width: 100%;
    padding: 0.5rem;
    margin: 1em 0 1em 0;
    background-color: lightgray;
    color: black;
}

.infobar-title {
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
}

.infobar-text {
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}

.infobar a {
    color: #FFF;
    text-decoration: underline;
}