footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1em;
    padding-bottom: 1em;
}

.left {
    display: inline-block;
    min-width: 50%;
    text-align: center;
    flex-grow: 1;
}

.right {
    display: inline-block;
    min-width: 50%;
    text-align: center;
    flex-grow: 1;
}

a {
    color: #000;
    text-decoration: none;
}

a:visited {
    color: #000;
    text-decoration: none;
}

a:hover {
    color: #e25303;
    text-decoration: underline;
}