.wishlist {
    // fill page
    display: flex;
    flex-direction: column;
    height: 100%;
    // content is centered
    margin: auto;
    text-align: center;
}

.content {
    flex: 1;
}
