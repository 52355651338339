.context-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    button {
        display: inline-block;
        flex-basis: 50%;
        min-width: 20em;
        flex-grow: 1;
        flex-shrink: 0;
        text-align: center;
        padding: 0.5em;
        cursor: pointer;    
    }
}
